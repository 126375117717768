<template>
  <v-data-table
    :headers="headers"
    :items="customerReturns"
    :loading="
      customerReturnStatusRequest.value === 'loading-getCustomerReturns'
    "
    calculate-widths
    class="tp-table-scroll tp-table__row-pointer datatable px-3 py-2"
    disable-pagination
    hide-default-footer
    item-key="id"
    loading-text="Đang tải dữ liệu"
    no-data-text="Không có dữ liệu"
    no-results-text="Không tìm thấy kết quả phù hợp"
    @click:row="viewDetail($event)"
  >
    <!-- Start: Body -->
    <template v-slot:body="{ items }">
      <tbody v-if="items.length > 0">
        <!-- Start: Total -->
        <tr class="yellow lighten-5">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="font-weight-bold">
            {{ customerReturnListTotals.total_goods_value | formatCurrency }}
          </td>
          <td class="font-weight-bold">
            {{ customerReturnListTotals.total_fee | formatCurrency }}
          </td>
          <td class="font-weight-bold">
            {{
              customerReturnListTotals.total_need_pay_customer | formatCurrency
            }}
          </td>
          <td></td>
        </tr>
        <!-- Start: Total -->
        <!-- Start: Items -->
        <tr v-for="item in items" :key="item.id" @click="viewDetail(item)">
          <td>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="copied-label"
                  v-bind="attrs"
                  v-on="on"
                  @click.stop="copyToClipboard(item.code)"
                >
                  {{ item.code }}
                </div>
              </template>
              <span>Sao chép</span>
            </v-tooltip>
          </td>
          <td>{{ item.created_at }}</td>
          <td>{{ item.branch_name }}</td>
          <td>{{ item.khachhang_name }}</td>
          <td>{{ item.created_user_name }}</td>
          <td>{{ item.goods_value | formatCurrency }}</td>
          <td>{{ item.phitrahang | formatCurrency }}</td>
          <td>{{ item.need_pay_customer | formatCurrency }}</td>
          <td class="text-center">
            <v-chip
              :color="item.status === 1 ? 'green' : 'red accent-2'"
              class="font-weight-bold"
              outlined
              small
            >
              {{ item.status === 1 ? "Đã trả" : "Đã hủy" }}
            </v-chip>
          </td>
        </tr>
        <!-- End: Items -->
      </tbody>

      <tbody v-else>
        <tr class="v-data-table__empty-wrapper">
          <td colspan="9">Không có dữ liệu</td>
        </tr>
      </tbody>
    </template>
    <!-- End: Body -->
  </v-data-table>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        {
          text: "Mã phiếu trả",
          align: "start",
          sortable: false,
          value: "code"
        },
        {
          text: "Thời gian tạo",
          align: "start",
          sortable: false,
          value: "created_at"
        },
        {
          text: "Chi nhánh",
          align: "start",
          sortable: false,
          value: "branch_name"
        },
        {
          text: "Khách hàng",
          align: "start",
          sortable: false,
          value: "khachhang_name"
        },
        {
          text: "Nhân viên",
          align: "start",
          sortable: false,
          value: "created_user_name"
        },
        {
          text: "Giá trị hàng trả",
          align: "start",
          sortable: false,
          value: "goods_value"
        },
        {
          text: "Phí trả hàng",
          align: "start",
          sortable: false,
          value: "phitrahang"
        },
        {
          text: "Cần trả khách",
          align: "start",
          sortable: false,
          value: "value"
        },
        {
          text: "Trạng thái",
          align: "center",
          sortable: false,
          value: "status"
        }
      ]
    };
  },
  computed: {
    customerReturnListTotals() {
      return this.$store.getters["CUSTOMER_RETURN/customerReturnListTotals"];
    },
    customerReturns() {
      return this.$store.getters["CUSTOMER_RETURN/customerReturns"];
    },
    customerReturnStatusRequest() {
      return this.$store.getters["CUSTOMER_RETURN/statusRequest"];
    }
  },
  filters: {
    formatCurrency(value) {
      // Create our number formatter.
      let formatter = new Intl.NumberFormat("vi-VN", {
        style: "currency",
        currency: "VND"
      });

      return formatter.format(value);
    }
  },
  methods: {
    copyToClipboard(str) {
      const el = document.createElement("textarea");

      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);

      this.$toast.show({
        name: "toast-action-alert",
        payload: {
          message: "Đã sao chép vào bộ nhớ tạm"
        }
      });
    },

    getIndexOfCustomerReturn(val) {
      const arr = this.customerReturns.map(item => item.id);

      return arr.indexOf(val);
    },

    async viewDetail(item) {
      await this.$store.dispatch(
        "CUSTOMER_RETURN/getCustomerReturnById",
        item.id
      );

      await this.$store.dispatch(
        "CUSTOMER_RETURN/getCustomerReturnTransactions",
        item.id
      );

      this.$modal.show({
        name: "modal-customer-return"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.datatable {
  .v-data-table-header {
    background-color: green;
  }

  .copied-label {
    cursor: pointer;
    display: inline-block;
  }

  .view-label {
    cursor: pointer;
    transition: all 0.4s ease;
  }
}
</style>
